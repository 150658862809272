@import "../../Areas/Mobile/styles/_defFuncValues.scss";
@import "../../Areas/Mobile/styles/_function.scss";
@import "../../Areas/Mobile/styles/variables.scss";
@import "../spinner.scss";

body.sidebar_open {
    left: toEmSize(340px, $desWidth);
    position: fixed;
}

#sidebar,
.sidebar {
    transition: transform 0.1s;
    transform: translate3d(-100%, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    height: var(--min-full-height, 100vh);
    z-index: 110;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: toEmSize(340px, $desWidth);
    font-family: 'Rubik';
    font-weight: normal;
    overflow: hidden;
    background-color: #fff;

    &__spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
    }

    .svg-spinner {
        height: 100px;
        width: 100px;
    }
}

#sidebar {
    background: #3a3c44;
}

.sidebars-container {
    position: relative;
    z-index: 1000;
}

.sidebar_open #sidebar,
.sidebars-container--activated .sidebar,
.sidebar-state-menu__input:checked + .sidebars-container .sidebar {
    transform: translate3d(0,0,0);
}

.sidebar__content {
    height: calc(100vh - 86px - 96px);
    overflow-y: auto;

    &::-webkit-scrollbar { /*скроллбар */
        -webkit-appearance: none;
        width: 6px;
        height: 2px;
        background-color: #EFEFF4;
    }

    &::-webkit-scrollbar-thumb { /*ползунок */
        background-color: #79c3f9;
        border-radius: 10px;
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
    }

    &::-webkit-resizer {
    }
}

.sidebar__content.sidebar__content--without-footer {
    height: calc(100vh - 86px);
}

.sidebar__content.sidebar__content--without-header {
    height: calc(100vh - 96px);
}

.sidebar__content.sidebar__content--without-header-without-footer {
    height: 100vh;
}

#sidebar_overlay,
.sidebar_overlay {
    background: rgba(0,0,0,0.2);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: none;
}

.sidebar_open #sidebar_overlay,
.sidebars-container--activated .sidebar_overlay,
.sidebar-state-menu__input:checked + .sidebars-container .sidebar_overlay {
    display: block;
}

/*#sidebar .head {
    padding: 0 12px;
    padding-left: 23px;
    line-height: toEm(58px, 18px);
    background: #232429;
    font-size: toEm(18px);
    display: block;

    img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        border-radius: 4px;
    }

    .username {
        max-width: 190px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

}*/

.sidebar .sidebar__header {
    padding: 26px 68px 23px 30px;
    font-size: 24px;
    border-bottom-color: #fff;
    color: #000;
    background-color: #fff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #EFEFF4;
    position: sticky;
    top: 0;
    z-index: 2;
}

.sidebar__close {
    position: absolute;
    top: 26px;
    right: 24px;
    border: 0;
    background-color: transparent;
    margin: 0;
    padding-right: 0;
    color: #2D9CEE;
    width: 33px;
    height: 33px;
    padding: 0;
    border: 0;
    cursor: pointer;

    svg {
        display: block;
        margin: 0 auto;
        width: 24px;
        height: 24px;
    }
}

.sidebar-container__btn,
.sidebar-container-save-btn {
    background-color: #2D9CEE;
    border-radius: 4px;
    border: 0;
    color: #fff !important;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    font-size: 16px;
    cursor: pointer;
    padding: 15px 20px;

    &:hover {
        background-color: #6ac0ff;
    }

    &:active {
        background-color: #0087ea;
    }

    &:disabled {
        background-color: #e2e3e4;
        color: #94969b;
    }
}

.sidebar__footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
    padding: 26px 33px 23px 30px;
    color: #000;
    background-color: #F9F9F9;
    border-top: 1px solid #EFEFF4;
    text-align: right;
}

.sidebar-content-static {
    visibility: hidden;
}

.sidebar__content-static--open,
.sidebar-state-menu__input:checked + sidebars-container [data-content-id="sidebarMenu"] {
    visibility: visible;
}

.sidebar--open-root {
    overflow: hidden;
}

@media (min-width: 1280px) {
    #sidebar,
    .sidebar {
        width: 65%;
    }
}


@media (min-width: 1440px) {
    #sidebar,
    .sidebar {
        width: 60%;
    }
}

@media (min-width: 1600px) {
    #sidebar,
    .sidebar {
        width: 50%;
    }
}


@media (min-width: 1921px) {

    #sidebar,
    .sidebar {
        width: 100%;
        max-width: 950px;
    }
}



.color-scheme-preview .sidebar {
    &_overlay {
        background: rgba(0, 0, 0, 0);
    }
}