@import "../../../../styles/_settings.scss";

.adv-modal, .adv-modal-floating {
    .adv-modal-inner {
        border-radius: $border-radius-base;
        background-color: #fff;
    }

    .modal-header {
        border-bottom-color: #ccc;
    }

    .modal-footer {
        border-top-color: #ccc;
        background-color: #f0f0f0;
    }

    .adv-modal-close {
        color: #fff;

        &:hover, &:active {
            color: #fff;
        }
    }

    .adv-modal-close {
        width: 30px;
        height: 30px;

        &:after {
            font-size: 30px;
            position: absolute;
            top: 0;
            right: 0;
            text-shadow: 0 0 9px rgba(0,0,0, .5);
        }
    }
}

@media (max-width: 48em), (max-height: 480px) {

    .adv-modal, .adv-modal-floating {

        .adv-modal-close {
            color: #fff;
        }
    }

    .adv-modal-floating {
        top: 0;
        left: 0;
        max-width: 100%;
        transform: translate3d(0px, 0px, 0px);

        .modal-header {
            cursor: default;
        }
    }

    .adv-close-outside {
        margin-left: 0;
    }
}
