@import "../../../../styles/_settings.scss";

.compare-control {
    font-size: $font-size-normal;
}

.compare-checkbox ~ .custom-input-text {
    .compare-text-not-added {
        display: inline;
    }

    .compare-text-added {
        display: none;
    }
}
.compare-checkbox:checked ~ .custom-input-text {
    .compare-text-not-added {
        display: none;
    }

    .compare-text-added {
        display: inline;
    }
}