.fl {
    float: left;
}

.fr {
    float: right;
}

.clear:after, .clear:before {
    content: "";
    display: table;
}

.clear:after {
    clear: both;
}

.rel {
    position: relative;
}

.hidden {
    display: none;
}

.big-z {
    z-index: 100;
}

.flexible-maps-container {
    position: relative;
    overflow: hidden;
}


.flexible-maps-container iframe,
.flexible-maps-container object,
.flexible-maps-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flexible-video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;

    &.noPadding {
        padding: 0;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.center-aligner {
    display: flex;
    align-items: center;
    justify-content: center;

    .align-item {
        flex: none;
    }
}

.text-floating {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    line-break: normal;
    hyphens: none;
}

.text-static {
    overflow-wrap: normal; /* не поддерживает IE, Firefox; является копией word-wrap */
    word-wrap: normal;
    word-break: normal; /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */
    line-break: auto; /* нет поддержки для русского языка */
    hyphens: manual;
}

.text-overflow {
    @extend .text-static;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}


.scaling-svg-container {
    position: relative;
    height: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 100%;

    svg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
    }
}

.no-font-boost {
    max-height: 999999px;
}

.hidden-xs {
    display: none !important
}

.visible-xs {
    display: block
}

@media (min-width: 48em) {
    .hidden-sm {
        display: none !important
    }

    .visible-sm {
        display: block !important
    }
}

@media (min-width: 62em) {
    .hidden-md {
        display: none !important
    }

    .visible-md {
        display: block !important
    }
}

@media (min-width: 75em) {
    .hidden-lg {
        display: none !important
    }

    .visible-lg {
        display: block !important
    }
}
.inline{
    display: inline;
}
.flex {
    display: flex;
}

.nowrap {
    white-space: nowrap;
}

.shrink-none {
    flex-shrink: 0;
}

.relative {
    position: relative;
}
/* MARGINS & PADDINGS */
.p-xxs {
    padding: 5px;
}

.p-xs {
    padding: 10px;
}

.p-sm {
    padding: 15px;
}

.p-m {
    padding: 20px;
}

.p-md {
    padding: 25px;
}

.p-lg {
    padding: 30px;
}

.p-xl {
    padding: 40px;
}

.p-w-xs {
    padding: 0 10px;
}

.p-w-sm {
    padding: 0 15px;
}

.p-w-m {
    padding: 0 20px;
}

.p-w-md {
    padding: 0 25px;
}

.p-w-lg {
    padding: 0 30px;
}

.p-w-xl {
    padding: 0 40px;
}

.p-n {
    padding: 0;
}

.p-r-none {
    padding-right: 0;
}

.p-r-lg {
    padding-right: 30px;
}

.p-r-xs {
    padding-right: 5px;
}

.p-r-xs-x2 {
    padding-right: 10px;
}

.p-r-sm {
    padding-right: 15px;
}

.p-r-md {
    padding-right: 25px;
}

.p-xs {
    padding: 5px;
}

.p-xs-x2 {
    padding: 10px;
}

.p-l-n {
    padding-left: 0;
}

.p-l-lg {
    padding-left: 30px;
}

.p-l-xs {
    padding-left: 5px;
}

.p-l-sm {
    padding-left: 15px;
}

.p-l-md {
    padding-left: 25px;
}

.p-t-lg {
    padding-top: 30px;
}

.p-t-xs {
    padding-top: 5px;
}

.p-t-sm {
    padding-top: 15px;
}

.p-t-md {
    padding-top: 25px;
}

.p-b-lg {
    padding-bottom: 30px;
}

.p-b-xs {
    padding-bottom: 5px;
}

.p-b-sm {
    padding-bottom: 15px;
}

.p-b-md {
    padding-bottom: 25px;
}

.p-b-n {
    padding-bottom: 0;
}

.m-xxs {
    margin: 2px 4px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-n-xxs {
    margin-left: -1px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-t-none {
    margin-top: 0;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-n-xxs {
    margin-top: -1px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-r-none {
    margin-right: 0;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-n-xxs {
    margin-right: -1px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-b-none {
    margin-bottom: 0;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-n-xxs {
    margin-bottom: -1px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.vertical-interval-xsmall {
    margin: 0.3125rem 0;
}

.vertical-interval-small {
    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
}

.vertical-interval-middle {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.vertical-interval-big {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
}

.vertical-interval-xsmall.no-top, .vertical-interval-small.no-top, .vertical-interval-middle.no-top, .vertical-interval-big.no-top {
    margin-top: 0;
}

.vertical-interval-xsmall.no-bottom, .vertical-interval-small.no-bottom, .vertical-interval-middle.no-bottom, .vertical-interval-big.no-bottom {
    margin-bottom: 0;
}

.horizontal-interval-xsmall {
    margin: 0 0.3125rem;
}

.horizontal-interval-small {
    margin: 0 0.4375rem;
}

.horizontal-interval-middle {
    margin: 0 0.625rem;
}

.horizontal-interval-big {
    margin: 0 0.9375rem;
}

.horizontal-interval-xsmall.no-left, .horizontal-interval-small.no-left, .horizontal-interval-middle.no-left, .horizontal-interval-big.no-left {
    margin-right: 0;
}

.horizontal-interval-xsmall.no-right, .horizontal-interval-small.no-right, .horizontal-interval-middle.no-right, .horizontal-interval-big.no-right {
    margin-left: 0;
}

.block{
    display: block;
}