@use "sass:math";

@import "../_settings.scss", "../functions/_util.scss", "../functions/_icons.scss", "../functions/_breakpoints.scss";

$menu-general-padding: rem-calc(6px) rem-calc(15px) rem-calc(8px);
$menu-general-icon-height: rem-calc(30px);

.menu-general-wrap {
    flex-wrap: nowrap;
}

.menu-general {
    padding: 0;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    font-size: 0;
    line-height: 0;
}

.menu-general-item {
    position: relative;
    display: inline-block;
    /*float: left;*/
    background-color: transparent;
    white-space: normal;
    line-height: 1;
    font-size: $font-size-normal;
    vertical-align: top;

    &:before {
        position: absolute;
        content: "";
        width: rem-calc(2px);
        display: block;
        vertical-align: middle;
        top: 0;
        right: 0;
        bottom: 0;
        margin-right: -1px;
        background: linear-gradient(to bottom, rgba(57,57,57,0.39) 0%,rgba(57,57,57,0.33) 17%,rgba(57,57,57,0.12) 61%,rgba(57,57,57,0.01) 96%,rgba(255,255,255,0.01) 98%,rgba(255,255,255,0) 100%) no-repeat border-box, linear-gradient(to bottom, rgba(255,255,255,0.14) 0%,rgba(255,255,255,0.02) 72%,rgba(255,255,255,0) 100%) no-repeat border-box;
        background-position: 0 0, 1px 0px;
        background-size: rem-calc(1px) 100%, rem-calc(1px) 100%;
    }

    &:first-child {
        border-top-left-radius: $border-radius-base;
        border-bottom-left-radius: $border-radius-base;
    }

    &.parent:first-child {
        border-bottom-left-radius: 0;
    }

    &:last-child {
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
    }

    &.parent:last-child {
        border-bottom-right-radius: 0;
    }
}

.menu-general-root-link {
    font-size: $font-size-medium;
    padding: $menu-general-padding;
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    //simulator icon
    &:before {
        display: inline-block;
        vertical-align: middle;
        height: $menu-general-icon-height;
        content: "";
        width: 0;
    }

    &:hover, &:active {
        text-decoration: none;
    }

    &:first-child {
        border-top-left-radius: $border-radius-base;
    }

    &:last-child {
        border-top-right-radius: $border-radius-base;
    }
}

.menu-general-tile {
    padding: $menu-general-padding;

    &:hover .harmonica-tile-dots {
        color: #4b4f58;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: $menu-general-icon-height;
    }

    &:before{
        display: none;
    }
}

.menu-general-tile-submenu {
    padding-top: rem-calc(35px);
    min-width: rem-calc(135px);
    font-size: $font-size-medium;
}

.menu-general-submenu {
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    margin-top: rem-calc(-1px);
    border-bottom-left-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
    white-space: nowrap;
    display: table;
    table-layout: fixed;
    padding: 0;
    margin: 0;
    list-style: none;


    &.submenu-initialized {
        opacity: 1;
    }
}

.menu-general-sub-column {
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    /*display: block;*/
    min-width: rem-calc(194px);
    position: relative;
}

.menu-general-sub-cell, .menu-general-sub-cell-multiple {
    display: table-cell;
    padding: rem-calc(10px) 0;
    vertical-align: top;
}

.menu-general-sub-cell {

    .menu-general-sub-column {
        display: block;
        padding-top: 0;
        padding-bottom: 0;
    }

    .menu-general-sub-brand-column {
        border-left-width: 1px;
        border-left-style: solid;
    }
}

.menu-general-sub-cell-multiple {
    .menu-general-sub-column {
        padding-top: 0;
        max-width: 232px;
        min-width: 232px;
        box-sizing: border-box;
        white-space: normal;
    }
}

.menu-general-sub-cell-multiple + .menu-general-sub-cell {
    .menu-general-sub-brand-column {
        max-width: 219px;
        min-width: 219px;
        box-sizing: border-box;
    }
}

.menu-general-sub-cell-multiple {
    .menu-general-sub-category > a,
    .menu-general-sub-category-parent > a {
        white-space: normal;
    }
}


.menu-general-sub-column, .menu-general-sub-banner {
    padding: rem-calc(10px) rem-calc(15px);
}

.menu-general-sub-row {
    display: table-row;
}

.menu-general-sub-category,
.menu-general-category-parent,
.menu-general-sub-category-parent,
.menu-general-sub-brand-title {
    margin-bottom: rem-calc(7px);
}

.menu-general-category-parent,
.menu-general-sub-category-parent,
.menu-general-sub-brand-title {
    font-size: $font-size-medium;

    &.true-parent {
        font-weight: bold;
    }
}
.menu-general-sub-column .menu-general-category-parent:first-child {
    margin-top: rem-calc(7px);
}
/*.menu-general-sub-brand-column {
    &:before {
        position: absolute;
        content: "";
        width: rem-calc(1px);
        display: block;
        vertical-align: middle;
        top: 0;
        left: 0;
        bottom: 0;
        background: #e2e3e4;
    }
}*/
.menu-general-sub-category-parent:not(:first-child) {
    margin-top: rem-calc(25px);
}

.menu-general-sub-childs {
    padding: 0;
    margin: 0;
    list-style: none;
}

.menu-general-sub-banner {
    text-align: center;
}

.menu-general-submenu ~ .menu-general-root-link:after {
    @include icon-base();
    content: "\e800";
    text-decoration: none;
    font-size: $font-size-xsmall;
    margin-left: rem-calc(5px);
}

.menu-general-icon,
.menu-general-root-link-text {
    display: inline-block;
    vertical-align: middle;
}

.menu-general-icon {
    line-height: 1;
    padding-right: math.div($padding-base, 2);
}

.menu-general .submenu-invert {
    transform: translateX(rem-calc(-10px));
}

.menu-general-icon-img {
    display: block;
}
.menu-general--with-search {
    flex-basis: 80%;
    max-width: 80%;
}