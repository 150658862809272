@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

.zone-dialog {
    width: rem-calc(670px);
}

.zone-dialog-title {
    font-size: 24px;
    padding: 0.625rem 0.625rem 0;
}


.zone-dialog .modal-content {
    padding: 0;
    min-height: 264px;
}

.zone-dialog-items {
    display: flex;
}

.zone-dialog-item {
    flex-grow: 1;
    vertical-align: top;
    padding-right: $padding-base;
}

.zone-dialog-item-link {
    display: block;
    padding: $padding-base;
    border-radius: $border-radius-base;
    border: 1px solid transparent;

    &:hover {
        border-color: #dedddd;
    }
}

.zone-dialog-text {
    font-size: $font-size-xmedium;
    padding-right: $padding-base;
}

.zone-dialog-countries,
.zone-dialog-autocomplete-block,
.zone-dialog-cities {
    padding: $padding-base $padding-base 0;
}

.zone-dialog-cities {
    padding-bottom: $padding-base;
}

.zone-dialog-autocomplete-block {
    margin-bottom: rem-calc(15px);
}

.zone-dialog-cities {
    border-top: 1px solid #e2e3e4;
}

.zone-dialog-input-wrap {
    position: relative;
}

.zone-dialog-input-wrap > input {
    font-size: 0.8rem;
    line-height: inherit;
}

.zone-dialog-country-link {
    display: inline-block;
    vertical-align: middle;
    font-size: $font-size-medium;
    padding: $padding-base;
    border: rem-calc(1px) transparent solid;
    border-radius: $border-radius-base;
}

.zone-dialog-country-image {
    width: 16px;
    height: 11px;
    display: none;
}

.zone-dialog-country-selected {
    border-color: #ccc;
}

.zone-dialog-loading {
    text-align: center;
    padding: 40px 20px;
}

.zone-dialog-country-RU {
    display: inline-block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAABnElEQVQYGQXBO25TQRQG4P/MzH3ZjgOJAhQIARugpU4LDStgB0hUrAdaKhokJAp6VkBLgSAG2/G98zxn5vB9NPNR0oaIhklCCH2/qqKtEdSVlFfj2KQys1qTKos2kqB2RMnc951qI2OgAAAjOUVStYRatRlXBLvD0dz8htIJw14JzUipkStKy0smdRs7bqjflDaIrsfNOuQz9+4tXr6meeaugpWYm1U0007BGkWJ4owp3Cvh5PH+wzf38VO7+/Ts397wjMQdM7WCyNWnOlhTUuWUDre+684fPplufj0zBT/FYs75NiM0L1aiIkjqBnuYT7v9rU/aMCwp+gTAO7M9loqQL1hReGqVgofI1ihYts6e14JljoH9/QpMa/fFv7jevcHxD0zJkUumGOrQb1L8UYurqcuBKppO6Spuv8bP7rnuMX6H/YsTD6Oz88H0ZYpyXhAWT1UsFCJOYA6Xr1Cc7e5EX6dHjzHHRfLm3gMXZhwS7LIyV4gBmWGNLh6k8aJHAXaX/QIoDYpe4WaCWhJ0atcVjmHUrSqMDmsP/AcXlh8PUg5argAAAABJRU5ErkJggg==');
}

.zone-dialog-country-UA {
    display: inline-block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAIAAAD5gJpuAAABnUlEQVQYGQXBS24TQRQF0Fuvqrqrux1/gmWwGCEYgFgCI/bBatgXE8QSAhJCYQAycpyI2PSvfq8e5yh8lGYF7idSxZcMXalYZPQoTpdQFT3PAlswPHRXL8afYl7S+f3b9da1TmHICAWVhqPFgWC4LaFMntBSGI0d1efTjflFy2EPiVMZ2wnlMUUVHZHn0QEqBe1FlQDKq2vG8a42VaQWyP8ufKlsQ44NC0rOueYQ+1kjqHbMnoJfdjsstQlD4tEibUoxKcEHEoESy0rlgJwglggWJecA9MG8efrlmbwr9pQ2Y6Ru0ZBGouSPYp8sE2f0USVKcOMa+2Z1o37c7V7tPnA6BPvdYwO0Fg80qZjuOysGNAWWmgsPq+r5t0+3ZisnXKDJtXZmbrRQI2cVO311xeGCGEnIWurjlOa/1YKMjhU2v7O5h+xcJKsePS+4yXEeWm10JSEOfoY2W8pNDAeT5jX8tXIKxCoTdGsKkSqmOsNPLL42ljRlD86uMp2KX2Hr18fpNkmqc+3qMMwYBSYvdY6WUgIPATlg3636P5f/qh//1U2CwQsAAAAASUVORK5CYII=')
}

.zone-dialog-country-BY {
    display: inline-block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAABnRSTlMAAAAAAABupgeRAAAAT0lEQVR42mNgoAf4feXap+SMD+4+QBLIPsPAgAtBwa9jJ97IKEMQkE1YA9BUuAaa2fBWyxDoByA5OG1gmMmAE2ENJcIayLEBOaYJaxhkAAA7hqtsQDQZkQAAAABJRU5ErkJggg==')
}

.zone-dialog-country-KZ {
    display: inline-block;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAABnRSTlMAAAAAAABupgeRAAABIElEQVR42mNgoAfg23BAZ/NKpvUnGNafIoAgwHjLknl7gzk2HEGSO8Ow7jwIrT+NRYP6prWNuzLZNhyDq+becMh48xKgtazrj6PogQDDzUv7didwbTgMFj3Lvv547o7Ka4fUTxww9ts2gXH9WYQeCFDbtLZuVw7beqANp5nXn5beuGPHfof/xxmACGgQ74YjTGg26G9eNnNPOPuGowzrLvpsm5iyo75mV+6HI0JPDsvFbW8BsrU3r8JmA9AP686pbVoXub3ddMuihO3N4ds7DTYvy91RIbxhLz4/iG7cLb1xm+PWWVZb5ttvmS0CUn0WLZTWNO7KQgqls8zrT2ltXm29ZZ7cpi3gwCUcD0B0Dqz0HJZ44N+wX2/zChJiepABAAOL6iNL5nH6AAAAAElFTkSuQmCC')
}
