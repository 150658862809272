.breadcrumbs {
    font-size: 16px;
    padding-bottom: 0;

    .main-title {
        text-align: left;
        font-weight: 600;
        font-size: 18px;
        padding-left: 0;
    }

    &__inner {
        display: flex;
        align-content: center;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 0.66667em;
        box-sizing: border-box;
        white-space: nowrap;
    }

    &__item {
        vertical-align: middle;
        display: flex;
        align-items: center;
        color: #8b8b8b;
        margin-right: 5px;

        &::before {
            display: block;
        }

        &:first-child::before {
            display: none;
        }
    }

    &--desktop {
        font-size: 11px;
        margin-bottom: 20px;

        .breadcrumbs__inner {
            scrollbar-width: thin;
            scrollbar-face-color: transparent; /* Firefox 63 compatibility */
            scrollbar-track-color: transparent; /* Firefox 63 compatibility */
            scrollbar-color: transparent transparent;

            &::-webkit-scrollbar {
                height: 8px;
                background-color: transparent;
                border-radius: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
                border-radius: 3px;
            }

            &:hover {
                scrollbar-face-color: #c1c1c1; /* Firefox 63 compatibility */
                scrollbar-track-color: #f1f1f1; /* Firefox 63 compatibility */
                scrollbar-color: #c1c1c1 #f1f1f1;

                &::-webkit-scrollbar {
                    background-color: #fff;
                }

                &::-webkit-scrollbar-track {
                    background-color: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #c1c1c1;
                }
            }
        }


        &__item {
            &::before {
                font-size: 9px;
            }
        }
    }
}
