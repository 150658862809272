.accordion-css {
    &__state {
        opacity: 0;
        position:absolute;

        &:checked ~ .accordion-css__body {
            display: block;
        }
    }

    &__body {
        display: none;
    }
}
