.social {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: hidden;
    font-size: 16px;
    margin: 0 5px;
    text-decoration: none;

    &:before {
        margin-right: 0;
    } 

    &:first-child {
        margin-left: 0;
    }


    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &--vkontakte {
        background-color: rgb(59, 74, 95);

        &:hover {
            background-color: lighten(rgb(59, 74, 95), 15%);
        }

        &:before {
            width: auto;
        }
    }

    &--facebook {
        background-color: rgb(63, 92, 150);

        &:hover {
            background-color: lighten(rgb(63, 92, 150), 15%);
        }
    }

    &--youtube {
        background-color: hsl(0, 100%, 50%);

        &:hover {
            background-color: lighten(hsl(0, 100%, 50%), 15%);
        }
    }

    &--twitter {
        background-color: rgb(86, 171, 238);

        &:hover {
            background-color: lighten(rgb(86, 171, 238), 15%);
        }
    }

    &--instagram {
        background-color: #d10869;

        &:hover {
            background-color: lighten(#d10869, 15%);
        }
    }

    &--telegram {
        background-color: #35ace1;

        &:hover {
            background-color: lighten(#35ace1, 15%);
        }
    }

    &--odnoklassniki {
        background-color: #ee8208;

        &:hover {
            background-color: lighten(#ee8208, 15%);
        }
    }
}
@media (max-width:768px){
    .social {
        margin-bottom: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}