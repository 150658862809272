/*logogenerator*/
@font-face {
    font-family: AstakhovDished;
    src: url('/fonts/AstakhovDished/AstakhovDished.woff2') format('woff2'), url('/fonts/AstakhovDished/AstakhovDished.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dobrozrachniy';
    src: url('/fonts/Dobrozrachniy/Dobrozrachniy-Regular.woff2') format('woff2'), url('/fonts/Dobrozrachniy/Dobrozrachniy-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fakedes Outline';
    src: url('/fonts/Fakedes_Outline/FakedesOutline.woff2') format('woff2'), url('/fonts/Fakedes_Outline/FakedesOutline.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jazz Ball';
    src: url('/fonts/JazzBall/JazzBall.woff2') format('woff2'), url('/fonts/JazzBall/JazzBall.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Krabuler';
    src: url('/fonts/Krabuler/RFKrabuler-Regular.woff2') format('woff2'), url('/fonts/Krabuler/RFKrabuler-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kurica Lapoi 1.3';
    src: url('/fonts/KuricaLapoi1.3/KuricaLapoi1.3.woff2') format('woff2'), url('/fonts/KuricaLapoi1.3/KuricaLapoi1.3.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pelmeshka';
    src: url('/fonts/Pelmeshka/Pelmeshka.woff2') format('woff2'), url('/fonts/Pelmeshka/Pelmeshka.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}
