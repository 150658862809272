@import "../_settings.scss", "../functions/_util.scss";

.footer-menu-head {
    font-size: $font-size-xmedium;
    margin: 0 0 rem-calc(20px);
}

.footer-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-menu-item {
    padding: 0;
    margin: 0 0 rem-calc(20px);
    list-style: none;
    font-size: $font-size-normal;
}

.footer-menu-icon-block {
    display: inline-block;
    margin-right: rem-calc(5px);
    line-height:0;
    font-size: 0;
    vertical-align: middle;
}
.footer-menu-icon{
    display:block;
}