@import "../_settings.scss";
@import "../functions/_util.scss";

table {
    padding: $vertical-interval-xsmall $horizontal-interval-xsmall;
    margin: 0;

    /*&:not([cellpadding]) {
        td, th {
            padding: $vertical-interval-xsmall $horizontal-interval-xsmall;
        }
    }*/

    &:not([cellspacing]) {
        border-collapse: collapse;

        td, th {
            border-collapse: collapse;
        }
    }
}

td, th {
    border-width: rem-calc(1px);
    border-style: solid;
}

.table-without-borders {
    border: 0;

    td, th {
        border: 0;
    }
}

.Oplata {
    border: 0;

    td, th {
        border: 0;
    }
}

/*inplace*/
table[align="center"] {
    margin-left: auto;
    margin-right: auto;
}

table[border="0"] {
    border: 0;

    td, th {
        border: 0;
    }
}

.hidden-xs-table {
    display: none !important
}

.visible-xs-table {
    display: table-cell !important;
}

@media (min-width: 48em) {

    .visible-sm-table {
        display: table-cell !important
    }

    .hidden-sm-table {
        display: none !important;
    }
}
